import { useState } from "react";
import Swal from "sweetalert2";
import MessageCard from "./MessageCard";
import img from "../../../assets/images/admin.png";
import { sendMessage } from "../../../api/Chat/ChatApi";

function ChatCard({ chat }) {
  const [isMessagesVisible, setIsMessagesVisible] = useState(false);

  const handleSendMessage = (receiverId) => {
    console.log(receiverId);
    Swal.fire({
      title: "Send a Message",
      input: "textarea",
      inputPlaceholder: "Type your message here...",
      showCancelButton: true,
      confirmButtonText: "Send",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      inputValidator: (value) => {
        if (!value) {
          return "Message content cannot be empty!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const messageContent = result.value;
        try {
          const response = await sendMessage(receiverId, messageContent);
          if (response.status === 200) {
            Swal.fire("Sent!", "Your message has been sent.", "success");
          }
        } catch (error) {
          Swal.fire("Error!", "Failed to send the message.", "error");
        }
      }
    });
  };

  return (
    <div className="border rounded-lg bg-white shadow-md">
      <div
        className="flex flex-col md:flex-row items-center justify-between p-4 cursor-pointer hover:bg-gray-100 transition"
        onClick={() => setIsMessagesVisible(!isMessagesVisible)}
      >
        <img
          src={chat.senderImage || img}
          alt={chat.senderName}
          className="lg:w-12 w-8 h-8 lg:h-12 rounded-full border"
        />
        <div className="flex flex-col items-end gap-4  justify-center">
          <div className="flex flex-col md:flex-row items-center justify-center gap-3">
            <p>Sender:</p>
            <p className="font-bold text-gray-800">{chat.senderName}</p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-center gap-3 mt-2 md:mt-0">
            <p>Receiver:</p>
            <p className="text-sm text-gray-500">{chat.receiverName}</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 items-center">
          <button
            className="bg-primary text-white px-3 py-1 text-sm rounded"
            onClick={(e) => {
              e.stopPropagation();
              handleSendMessage(chat.senderId);
            }}
          >
            Send Sender
          </button>
          <button
            className="bg-primaryOpacity text-black px-3 py-1 text-sm rounded"
            onClick={(e) => {
              e.stopPropagation();
              handleSendMessage(chat.receiverId);
            }}
          >
            Send Receiver
          </button>
        </div>
        <img
          src={chat.receiverImage || img}
          alt={chat.receiverName}
          className="w-12 h-12 rounded-full border"
        />
        <button
          className={`bg-gray-200 p-2 rounded-full ml-4 transform transition-transform ${
            isMessagesVisible ? "rotate-180" : ""
          }`}
        >
          ▼
        </button>
      </div>

      {/* Messages Section */}
      <div
        className={`overflow-hidden transition-all duration-500 ${
          isMessagesVisible ? "max-h-[70vh]" : "max-h-0"
        }`}
      >
        {chat.messages.length > 0 ? (
          <div className="p-4 space-y-4 bg-gray-50 overflow-y-auto max-h-96">
            {chat.messages.map((message) => (
              <MessageCard key={message.id} message={message} chat={chat} />
            ))}
          </div>
        ) : (
          <p className="p-4 text-center text-sm text-gray-500">
            No messages in this conversation.
          </p>
        )}
      </div>
    </div>
  );
}

export default ChatCard;
