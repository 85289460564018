import React from "react";
import DashBoardSatistics from "./DashBoardSatistics";
import OrderAndTransfers from "./OrderAndTransfers";
import StaticContainerAnalyzer from "./StaticContainerAnalyizer";
import img1 from "../../../assets/images/Co.png";
import img2 from "../../../assets/images/Frame 3298.png";
import img3 from "../../../assets/images/Coins.png";
import img4 from "../../../assets/images/Frame 3299.png";
function DashBoardContent({ data }) {
  console.log("from dashboard content", data);
  return (
    <>
      <DashBoardSatistics dataStatics={data} />
      <div className="my-12 flex justify-center items-center flex-wrap  gap-5 md:gap-10">
        <StaticContainerAnalyzer
          img={img1}
          altText="Coins Image"
          title="USD"
          subtitle="All Amount"
          amount={data.totalAmount || " 0"}
        />
        <StaticContainerAnalyzer
          img={img2}
          altText="Coins Image"
          title="Num"
          subtitle="Orders"
          amount={data.completedOrderCount || " 0"}
        />
        <StaticContainerAnalyzer
          img={img3}
          altText="Coins Image"
          title="USD"
          subtitle="Tasker"
          amount={data.companyShare || " 0"}
        />
        <StaticContainerAnalyzer
          img={img4}
          altText="Coins Image"
          title="Workers"
          subtitle="Workers"
          amount={data.workerAmount || " 0"}
        />
      </div>
      <OrderAndTransfers data={data} />
    </>
  );
}

export default DashBoardContent;
