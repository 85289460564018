import React from "react";
import CustomSelect from "../../common/CustomSelect";
import TransactionList from "./TransactionList";
import SelectedUser from "./SelectedUser";
import { useTransactions } from "../../../context/transactions/TransationsContext";
function TransactionSelectedUsers({ transactionData }) {
  const {
    fetchOrderDetails,
    selectedOrderDetails,
    orderDetailsLoading,
    orderDetailsError,
  } = useTransactions();
  return (
    <div className="flex flex-col lg:flex-row  justify-center mt-2 gap-5 sm:gap-7">
      <div className="w-full  bg-white  p-2 md:p-4 lg:p-6  lg:w-1/2 rounded-md shadow-sm">
        <h3 className="p-2 text-primary font-medium text-sm md:text-lg lg:text-xl">
          Transactions Users
        </h3>
        {/* <div className=" my-2 md:my-5 flex flex-wrap   items-center justify-center gap-4 md:gap-2 md:justify-end">
          <div class="relative flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="absolute w-5 h-5 top-2.5 left-2.5 text-mainTextColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                clip-rule="evenodd"
              />
            </svg>

            <input
              class="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
              placeholder="Search"
            />
          </div>
          <CustomSelect />
        </div> */}
        {transactionData && (
          <TransactionList
            fetchOrderDetails={fetchOrderDetails}
            transactionData={transactionData}
          />
        )}
      </div>

      <SelectedUser
        data={selectedOrderDetails}
        loading={orderDetailsLoading}
        error={orderDetailsError}
      />
    </div>
  );
}

export default TransactionSelectedUsers;
