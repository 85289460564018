import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import Popover from "@mui/material/Popover";
import CustomSelect from "../../common/CustomSelect";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { useUnderReview } from "../../../context/underPreview/UnderPreview";
import Swal from "sweetalert2";
import { AiOutlineEye } from "react-icons/ai"; // Import Eye icon
function WorkersTable({ data }) {
  const { changeStatus } = useUnderReview();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const paginatedData = data.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalPages = Math.ceil(data?.length / pageSize);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleStatusChange = (workerId, newStatus) => {
    Swal.fire({
      title: "Change Worker Status",
      html: `
        <div style="text-align: left; margin-top: 10px;">
          <p style="font-size: 16px; margin-bottom: 8px;">
            Do you want to change the worker's status to 
            <strong style="color: #3085d6;">${newStatus}</strong>?
          </p>
          <label for="statusMessage" style="display: block; margin-bottom: 6px; font-weight: bold;">
            Add a message:
          </label>
          <textarea 
            id="statusMessage" 
            class="swal2-textarea" 
            placeholder="Enter your message here..." 
            rows="4" 
            style="width: 100%; margin: 0 auto; padding: 8px; font-size: 14px; border: 1px solid #dcdcdc; border-radius: 4px; resize: none;">
          </textarea>
        </div>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      customClass: {
        popup: "custom-swal-popup",
        title: "custom-swal-title",
        cancelButton: "custom-swal-cancel-button",
        confirmButton: "custom-swal-confirm-button",
      },
      preConfirm: () => {
        const message = document.getElementById("statusMessage").value.trim();
        // if (!message) {
        //   Swal.showValidationMessage(
        //     "Please enter a message before proceeding."
        //   );
        //   return false;
        // }
        return { message }; // Returning the message input
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { message } = result.value; // Accessing the input message
        changeStatus(workerId, newStatus, message) // Pass message to changeStatus
          .then(() => {
            Swal.fire(
              "Updated!",
              "The worker's status has been updated.",
              "success"
            );
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "Something went wrong while updating the status.",
              "error"
            );
          });
      }
    });
  };

  return (
    <div className="p-3 sm:p-6 md:p-8 my-10 flex items-center justify-center rounded-md">
      <div className="overflow-x-auto w-[350px] sm:w-[500px] md:[550px] xl:w-[1000px] 2xl:w-[1300px]">
        <table className="min-w-full">
          <thead>
            <tr className="border-b">
              <th className="p-4  text-sm font-medium text-fiveTextColor border-gray-200 text-center">
                FullName
              </th>
              <th className="p-4  text-sm font-medium text-fiveTextColor border-gray-200 text-center">
                Experience Country
              </th>
              <th className="p-4  text-sm font-medium text-fiveTextColor border-gray-200 text-center">
                Phone Number
              </th>
              <th className="p-4  text-sm font-medium text-fiveTextColor border-gray-200 text-center">
                Address
              </th>
              <th className="p-4  text-sm font-medium text-fiveTextColor border-gray-200 text-center">
                Status
              </th>
              <th className="p-4  text-sm font-medium text-fiveTextColor border-gray-200 text-center">
                Show
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((worker, index) => (
              <tr key={index} className="hover:bg-slate-50 border-b">
                <td className="p-4 text-mainTextColor text-[12px] sm:text-sm md:text-md text-center">
                  {worker.name}
                </td>
                <td className="p-4 text-mainTextColor text-[12px] sm:text-sm md:text-md text-center">
                  {worker.experienceCountry}
                </td>
                <td className="p-4 text-mainTextColor text-[12px] sm:text-sm md:text-md text-center">
                  {worker.phoneNumber}
                </td>
                <td className="p-4 text-mainTextColor text-[12px] sm:text-sm md:text-md text-center">
                  {worker.address || "Not Found"}
                </td>
                <td className="p-4 text-mainTextColor w text-[12px] sm:text-sm md:text-md text-center">
                  <div
                    className="flex items-center justify-center gap-1 cursor-pointer"
                    onClick={handleClick}
                  >
                    <span className="bg-primary flex items-center gap-3 p-1 text-[10px] sm:text-sm md:text-md text-white rounded-md">
                      {worker.status || "Not Approved"}
                      <IoIosArrowDown size={20} className="text-white" />
                    </span>
                  </div>

                  {/* Material-UI Dropdown Menu */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {["Waiting for approval", "Certified", "Ban"].map(
                      (status) => (
                        <MenuItem
                          key={status}
                          onClick={() => handleStatusChange(worker.id, status)}
                          className="text-mainTextColor text-[12px] sm:text-sm"
                        >
                          {status}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </td>

                <td className="p-4 text-mainTextColor w text-[12px] sm:text-sm md:text-md text-center">
                  <AiOutlineEye
                    onClick={() => navigate(`/worker-preview/${worker.id}`)}
                    size={25}
                    className="text-primary cursor-pointer ml-4"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WorkersTable;
