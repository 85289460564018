function MessageCard({ message, chat }) {
  const isWorkerMessage = message.senderStatus !== "Customer";

  return (
    <div
      className={`flex ${
        isWorkerMessage ? "justify-end" : "justify-start"
      } items-start space-x-2`}
    >
      <div>
        {/* <p className="  font-mono text-center text-[8px]  ">{message.senderName}</p> */}
        <img
          src={
            message.senderImage ||
            "https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
          }
          alt={message.senderName}
          className="w-8 h-8 rounded-full"
        />
      </div>

      <div
        className={`px-4 py-1 rounded-lg text-[10px] ${
          isWorkerMessage
            ? "bg-primary text-white"
            : "bg-gray-200 text-gray-800"
        }`}
      >
        <p>{message.content}</p>
        <span className="block text-[7px] text-gray-900 mt-1">
          {new Date(message.sentAt).toLocaleString()}
        </span>
      </div>
    </div>
  );
}

export default MessageCard;
